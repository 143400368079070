import React from "react";
import "./ContactForm.scss";
import { Col, Row } from "react-bootstrap";

const ContactForm = () => {
  return (
    <section className="contactForm">
      <div className="container">
        <div className="heading">
          <h2>Contact us</h2>
        </div>

        <form>
          <Row>
            <Col md={6}>
              <div className="inputDiv">
                <input type="text" placeholder="Name*" />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv">
                <input type="text" placeholder="Phone" />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv">
                <input type="text" placeholder="Email*" />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv">
                <input type="text" placeholder="Message*" />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv file">
                <div className="textDiv">
                  <h6>Attach your file</h6>
                  <p>Upto 20MB</p>
                </div>
                <input type="file" />
              </div>
            </Col>
            <Col md={6}>
              <div className="privacy">
                <p>
                  We will process your personal information in accordance with
                  our <a>Privacy Policy.</a>
                </p>
                <label class="checkbox">
                  <div>
                    <input type="checkbox" class="checkbox__input" />
                    <span class="checkbox__inner"></span>
                  </div>
                  <p>
                    I would like to be contacted with news and updates about
                    your events and services
                  </p>
                </label>
              </div>
            </Col>
          </Row>

          <div className="btn-div">
            <button>Send message</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
