import Hero from "Common/Hero/Hero";
import React from "react";

const IOT = () => {
  return (
    <div>
      <Hero
        imageLink={
          "https://itrexgroup.com/wp-content/uploads/2021/07/IoT-development-consulting-services.jpeg"
        }
        heading={"IoT development & consulting services"}
        text={
          "As part of our IoT development and consulting services, we help businesses capture sensor data, run it through AI algorithms, present insights to end-users in an intuitive way, and orchestrate connected devices"
        }
        btnText={"Contact IoT Developers "}
        bgColor={"#000"}
      />
    </div>
  );
};

export default IOT;
