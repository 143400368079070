import Hero from "Common/Hero/Hero";
import React from "react";

const BlockChain = () => {
  return (
    <div>
      <Hero
        imageLink={
          "https://itrexgroup.com/wp-content/uploads/2022/01/blockchain-development-services-1-1.png"
        }
        heading={"Blockchain development services"}
        text={
          "Enjoy maximum transparency and never-failing security of data and transactions with our blockchain development services"
        }
        btnText={"Consult Blokchain Developers "}
        bgColor={"#000D2D"}
      />
    </div>
  );
};

export default BlockChain;
