import React, { useState } from "react";
import { Container } from "react-bootstrap";

import logo from "../../assets/images/logo.svg";

import "./Header.scss";
import { NavLink } from "react-router-dom";

let options = [
  {
    name: "Solutions",
    path: "",
    subMenu: [
      {
        name: "Data",
        path: "/services/big-data",
        subMenu: [
          {
            name: "Data Analytics",
            path: "/services/data-analytics",
          },
          {
            name: "Data Architecture",
            path: "/services/data-architecture",
          },
          {
            name: "Data Engineering",
            path: "/services/data-enginnering",
          },
          {
            name: "Data Management",
            path: "/services/data-management",
          },
          {
            name: "Data Migration",
            path: "/services/data-migration",
          },
          {
            name: "Data Integration",
            path: "/services/data-integration",
          },
          {
            name: "Data Science",
            path: "/services/data-science",
          },
          {
            name: "Data Warehouse",
            path: "/services/data-wareehouse",
          },
          {
            name: "Data Visualization",
            path: "/services/data-visualization",
          },
          {
            name: "Business Intelligence",
            path: "/services/business-intelligence",
          },
          {
            name: "Enterprise Data PlatformData Management",
            path: "/services/enterprise-data-platform-management",
          },
        ],
      },
      {
        name: "Artificial Intelligence",
        path: "/services/artificial-intelligence",
        subMenu: [
          {
            name: "AI for Education",
            path: "/services/ai-for-education",
          },
          {
            name: "AI for Healthcare",
            path: "/services/ai-for-healthcare",
          },
          {
            name: "AI for Retail",
            path: "/services/ai-for-retail",
          },
          {
            name: "Anomaly Detection",
            path: "/services/anomaly-detection",
          },
          {
            name: "Chatbot Development",
            path: "/services/chatbot-development",
          },
          {
            name: "Computer Vision",
            path: "/services/computer-vision",
          },
          {
            name: "Deep Learning",
            path: "/services/computer-vision",
          },
          {
            name: "Generative AI",
            path: "/services/generative-ai",
          },
          {
            name: "Machine Learning",
            path: "/services/machine-learning",
          },
          {
            name: "NLP",
            path: "/services/natural-language-processing",
          },
        ],
      },
      {
        name: "Automation",
        path: "/services/intelligent-process-automation/",
        subMenu: [
          {
            name: "RPA",
            path: "/services/rpa",
          },
        ],
      },
      {
        name: "Blockchain",
        path: "/services/blockchain-development",
        subMenu: [
          {
            name: "Smart Contract",
            path: "/services/smart-contracts",
          },
        ],
      },
      {
        name: "Extended Reality",
        path: "/services/extended-reality",
        subMenu: [
          {
            name: "Augmented Reality",
            path: "/services/augmented-reality",
          },
          {
            name: "Virtual Reality",
            path: "/services/virtual-reality",
          },
          {
            name: "Metaverse",
            path: "/services/metaverse",
          },
        ],
      },
      {
        name: "Internet of Things",
        path: "/services/iot-development-consulting",

        subMenu: [
          {
            name: "Embedded Software",
            path: "/services/embedded-software",
          },
          {
            name: "Industrial IoT",
            path: "/services/industrial-iot",
          },
          {
            name: "Medical IoT",
            path: "/services/medical-iot",
          },
        ],
      },
    ],
  },
  {
    name: "Services",
    path: "/services/software-engineering",
    subMenu: [
      {
        name: "Mobile",
        path: "/services/mobile-app-development",
        subMenu: [
          {
            name: "Android",
            path: "/services/android-app-development",
          },
          {
            name: "iOS",
            path: "/services/ios-app-development",
          },
          {
            name: "Cross-Platform",
            path: "/services/cross-platform-app-development",
          },
        ],
      },
      {
        name: "Web",
        path: "/services/custom-web-development",
      },
      {
        name: "SaaS",
        path: "/services/saas-development",
      },
      {
        name: "Back-End",
        path: "/services/backend-development",
      },
      {
        name: "Cloud & DevOps",
        path: "/services/cloud-and-devops",

        subMenu: [
          {
            name: "Cloud Migration",
            path: "/services/cloud-migration",
          },
          {
            name: "DevOps",
            path: "/services/devops",
          },
          {
            name: "Kubernetes",
            path: "/services/kubernetes",
          },
        ],
      },
      {
        name: "Dedicated Team",
        path: "/services/dedicated-development-team",
        subMenu: [
          {
            name: "CTO as a Service",
            path: "/services/cto-as-a-service",
          },
          {
            name: "React / NextJs Development",
            path: "/services/next-js-development",
          },

          {
            name: "Angular Development",
            path: "/services/angular-development",
          },
          {
            name: "Node.js Development",
            path: "/services/node-js-development",
          },
          {
            name: "Java Development",
            path: "/services/java-development",
          },
          {
            name: ".NET Development",
            path: "/services/net-development",
          },
        ],
      },
      {
        name: "QA & Testing",
        path: "/services/software-tessting-qa",
        subMenu: [
          {
            name: "Automation Testing",
            path: "/services/automated-tessting",
          },
          {
            name: "Manual Testing",
            path: "/services/manual-tessting",
          },
          {
            name: "Mobile Testing",
            path: "/services/mobile-tessting",
          },
          {
            name: "Web Testing",
            path: "/services/web-tessting",
          },
        ],
      },
      {
        name: "Technology Consulting",
        path: "/services/technology-consulting",

        subMenu: [
          {
            name: "Digital Transformation",
            path: "/services/digital-transformation-services",
          },
          {
            name: "Legacy Software Modernization",
            path: "/services/legacy-software-modernization",
          },
          {
            name: "MVP Development",
            path: "/services/mvp-development",
          },
        ],
      },
      {
        name: "UI/UX Design",
        path: "/services/ui-ux-design",
      },
      {
        name: "Support & Maintenance",
        path: "/services/it-support-maintainence",
      },
    ],
  },
  {
    name: "Industries",
    path: "",
    subMenu: [
      {
        name: "Healthcare & Biotech",
        path: "/services/healthcare-software-development",

        subMenu: [
          {
            name: "Biotech",
            path: "/services/biotech-application-development",
          },
          {
            name: "Fitness & Wellness",
            path: "/services/fitness-application-development",
          },
          {
            name: "Life Science",
            path: "/services/life-sciences",
          },
          {
            name: "mHealth",
            path: "/services/healthcare-app-development",
          },
          {
            name: "Pharma",
            path: "/services/pharmaceutical-software-solutions/",
          },
        ],
      },
      {
        name: "Logistics & Transportation",
        path: "/services/transportation-logistics-software-development",
      },
      {
        name: "Manufacturing & Supply Chain",
        path: "/services/manufacturing-suply-chain",
      },
      {
        name: "Media & Entertainment",
        path: "/services/media-entertainment",
      },
      {
        name: "Retail",
        path: "/services/retail-software-development",
      },
      {
        name: "EdTech & eLearning",
        path: "/services/e-learning-software-development",
      },
      {
        name: "FinTech",
        path: "/services/fintech-software-development",
      },
      {
        name: "Ecommerce",
        path: "/services/e-commerce-development",
        subMenu: [
          {
            name: "Adobe Commerce",
            path: "/services/adobe-commerce-development",
          },
        ],
      },
    ],
  },
  {
    name: "Company",
    path: "",
    subMenu: [
      {
        name: "About",
        path: "/about",
        subMenu: [
          {
            name: "Sustainability Policy",
            path: "/sustainability-policy",
          },
          {
            name: "Support Ukraine",
            path: "/support-ukraine",
          },
        ],
      },
      {
        name: "Careers",
        path: "/careers",
        subMenu: [
          {
            name: "Relocation",
            path: "/relocation",
          },
        ],
      },
      {
        name: "How we work",
        path: "how-we-work",
      },
    ],
  },
  {
    name: "Our Thinking",
    path: "/our-thinking",
  },
  {
    name: "Case Studies",
    path: "case-studies",
  },
];

function Header() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <header>
      <Container>
        <div className="flex-div">
          <div className="logoDiv">
            <NavLink to={"/"} onClick={scrollToTop}>
              <img src={logo} />
            </NavLink>
          </div>

          <div className="navlinks">
            <ul className="mainLinks">
              {options.map((a, i) => (
                <li key={i}>
                  <NavLink to={a.path} onClick={scrollToTop}>
                    {a.name}
                    {a.subMenu && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    )}
                    {a.subMenu && (
                      <div className="optionsMain">
                        <ul className="optionUl">
                          {a.subMenu.map((b, j) => (
                            <li key={`${i}-${j}`}>
                              <NavLink to={b.path} onClick={scrollToTop}>
                                {b.name}
                                {b.subMenu && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-caret-right-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                  </svg>
                                )}
                                {b.subMenu && (
                                  <ul>
                                    {b.subMenu.map((c, k) => (
                                      <li key={`${i}-${j}-${k}`}>
                                        <NavLink
                                          to={c.path}
                                          onClick={scrollToTop}
                                        >
                                          {c.name}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </NavLink>
                  {a.subMenu && <div className="beforeHead"></div>}
                </li>
              ))}
            </ul>
            <div className="headBtn">
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
