import Hero from "Common/Hero/Hero";
import React from "react";

const Automation = () => {
  return (
    <div>
      <Hero
        imageLink={
          "https://itrexgroup.com/wp-content/uploads/2021/06/intelligent-process-automation-services-e1623173768126.jpg"
        }
        heading={"Intelligent process automation services and solutions"}
        text={
          "Derive insights from operational data, pinpoint inefficiencies, and automate workflows with our state-of-the-art intelligent process automation (IPA) and RPA solutions"
        }
        btnText={"Talk to IPA Experts "}
        bgColor={"#000000"}
      />
    </div>
  );
};

export default Automation;
