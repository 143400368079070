import React from "react";

import herobg from "../../assets/images/herobg.mp4";

import accord_1 from "../../assets/images/accord_1.gif";
import accord_2 from "../../assets/images/accord_2.gif";
import accord_3 from "../../assets/images/accord_3.gif";
import accord_4 from "../../assets/images/accord_4.gif";
import accord_5 from "../../assets/images/accord_5.gif";
import accord_6 from "../../assets/images/accord_6.gif";

import Slider from "react-slick";

import "./Home.scss";
import { Col, Row } from "react-bootstrap";

function Home() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "150px",
  };

  return (
    <div className="homeMain">
      <section className="section1">
        <div className="videoDiv">
          <video autoPlay={true} loop={true} muted={true} playsInline={true}>
            <source src={herobg} type="video/mp4" />
          </video>
        </div>
        <div className="container mx-auto">
          <div className="heading">
            <h1>
              <span>Next-gen enterprise </span> <br />
              software development company
            </h1>
          </div>
          <div className=" flex justify-between items-center actionDiv">
            <div className="heading">
              <h3>
                We think <span>Future</span>
              </h3>
            </div>
            <div className="btn-div">
              <button>Lets Talk</button>
            </div>
          </div>
        </div>
      </section>

      <section className="section2">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img
                src="https://itrexgroup.com/wp-content/uploads/2021/11/main_clients-2.svg"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section3">
        <div className="container">
          <div className="heading">
            <h2>How we can help you</h2>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={accord_1} />
                    <span>
                      <small>01</small>
                      Innovation Consulting
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Make your digital transformation journey super simple, safe,
                  and aligned with your specific business needs. We're good at
                  strategy planning for both enterprises and SMBs, IT audit,
                  business process analysis, and more.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <img src={accord_2} />
                    <span>
                      <small>02</small>
                      Digital Transformation
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Achieve lower costs, utmost scalability and maximum velocity
                  with our legacy software modernization, cloud, and DevOps
                  services. Our enterprise software development company is here
                  to help you remove the roadblocks to innovation.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <img src={accord_3} />
                    <span>
                      <small>03</small>
                      Software Engineering
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Build enterprise software solutions that perform. We can cover
                  you along the whole way or fill in specific skill gaps in
                  business analysis, UX/UI, testing, development, or support
                  across web, mobile, desktop, cloud, and embedded platforms.
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <img src={accord_4} />
                    <span>
                      <small>04</small>
                      Big Data & Analytics
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Use your torrents of data to drive decision-making across the
                  company. From Data Governance & Data Management to Data
                  Analytics and Data Science, we make business, not IT, own your
                  data.
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <img src={accord_5} />
                    <span>
                      <small>05</small>
                      Artificial Intelligence
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Implement AI to beat real-world business challenges and meet
                  your KPIs, getting beyond the hype. From ML, NLP, and Computer
                  Vision to integration of AI technologies for Intelligent
                  Automation, we’ve done it all for our delighted clients.
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <img src={accord_6} />
                    <span>
                      <small>06</small>
                      Extended Reality
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseSix"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Deliver immersive experiences through AR, VR, or MR to create
                  new products, boost productivity, or launch into the
                  metaverse. XR solutions help you bypass distance challenges
                  and be innovative for real.
                </div>
              </div>
            </div>
          </div>

          <div className="btn-div">
            <button>Discuss my project</button>
          </div>
        </div>
      </section>

      <section className="section4">
        <div className="container">
          <div className="heading">
            <h2>Latest projects</h2>
          </div>
        </div>
        <div className="sliderDiv">
          <Slider {...settings}>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project1.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project2.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project3.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project4.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project5.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(${require("../../assets/images/project6.webp")})`,
                  }}
                ></div>
                <div className="heading">
                  <h4>AI-based enterprise software for No.1 retailer</h4>
                </div>
                <p>
                  Self-service analytics enabled by AI algorithms empowers 3
                  million users with instant actionable insights.
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading">
              <h5>View all cases</h5>
            </div>
            <div className="btn-div">
              <button>Start your innovation journey</button>
            </div>
          </div>
        </div>
      </section>

      <section className="section5">
        <div className="container">
          <div className="heading">
            <h2>How we collaborate with you</h2>
          </div>
          <div className="collabDiv">
            <Row>
              <Col md={6}>
                <div className="flex-div">
                  <div className="img-div">
                    <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate1.png" />
                  </div>
                  <div className="text-div">
                    <div className="heading">
                      <h3>Thinking big</h3>
                      <p>
                        After digging deep to understand holistically your
                        challenges and business objectives, we chart your
                        technology path that will keep your business
                        future-proof.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="flex-div">
                  <div className="img-div">
                    <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate2.png" />
                  </div>
                  <div className="text-div">
                    <div className="heading">
                      <h3>Starting small</h3>
                      <p>
                        At the beginning of the transformation journey, we start
                        with simple use cases that bring you immediate results.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="flex-div">
                  <div className="img-div">
                    <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate3.png" />
                  </div>
                  <div className="text-div">
                    <div className="heading">
                      <h3>Creating value fast</h3>
                      <p>
                        We then build an MVP, testing the first use cases and
                        collecting feedback. Along the way, we introduce
                        enhancements to align business deliverables and add new
                        use cases.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="flex-div">
                  <div className="img-div">
                    <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate4.png" />
                  </div>
                  <div className="text-div">
                    <div className="heading">
                      <h3>Innovating at scale</h3>
                      <p>
                        We are expanding the functionality of your system,
                        keeping it flexible on the tech stack, hugely adaptable
                        to humans, and easily scalable to evolve along with your
                        business growth.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="btn-div">
            <button>Let’s collaborate</button>
          </div>
        </div>
      </section>

      <section className="section4">
        <div className="container">
          <div className="heading">
            <h2>Latest thinking</h2>
          </div>
        </div>
        <div className="sliderDiv">
          <Slider {...settings}>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2021/01/AI-preview-1-1024x1024.jpg)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>
                    How to implement AI in your organization: the definitive
                    guide [with a free eBook inside]
                  </h4>
                </div>
                <p>
                  How to implement AI in your business and achieve a substantial
                  return on your artificial intelligence investments? Our AI
                  eBook will help you with that!
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2022/04/hector-j-rivas-1FxMET2U5dU-unsplash-1024x687.jpg)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>
                    IPA vs. RPA vs. BPA: what is the right automation option for
                    your company?
                  </h4>
                </div>
                <p>
                  Could you tell the difference between BPA, RPA, and IPA
                  without running a few Google searches? If not, don’t bother
                  surfing — we’ve done the job for you!
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2022/03/iStock-1369826066-min-1024x512.jpg)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>Metaverse for business leaders: complete guide</h4>
                </div>
                <p>
                  From immersive gaming environments to full-blown digital
                  auctions where NFTs are traded, metaverse can be a multitude
                  of things. And the reasons to start using metaverse for
                  business are as diverse as the technology’s definition.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2022/03/support-ukraine-1024x681.png)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>
                    ITRex launches two more initiatives to help Ukrainians
                  </h4>
                </div>
                <p>
                  Learn how ITRex continues to provide much-needed assistance to
                  Ukranians living in crisis since Russia began its invasion.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2022/05/boxed-water-is-better-zuj2m7qxDA0-unsplash-min-1024x683.jpg)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>5 reasons to adopt IoT in sports and fitness</h4>
                </div>
                <p>
                  Discover the exciting applications of IoT in the sports sector
                  and learn how to implement such solutions. Also, check out a
                  project from our portfolio to see how IoT and AI can
                  revolutionize our at-home training.
                </p>
              </div>
            </div>
            <div>
              <div className="projectCard">
                <div
                  className="cardImg"
                  style={{
                    backgroundImage: `url(https://itrexgroup.com/wp-content/uploads/2021/04/cost-of-artificial-intelligence-1-1-e1619715940595-1024x777.jpg)`,
                  }}
                ></div>
                <div className="heading">
                  <h4>How much does artificial intelligence cost? </h4>
                </div>
                <p>
                  Learn about the key factors influencing AI development costs
                  and how you can spend less and see ROI faster.
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading">
              <h5>View more insights</h5>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="contactForm">
        <div className="container">
          <div className="heading">
            <h2>Contact us</h2>
          </div>

          <form>
            <Row>
              <Col md={6}>
                <div className="inputDiv">
                  <input type="text" placeholder="Name*" />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <input type="text" placeholder="Phone" />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <input type="text" placeholder="Email*" />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <input type="text" placeholder="Message*" />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv file">
                  <div className="textDiv">
                    <h6>Attach your file</h6>
                    <p>Upto 20MB</p>
                  </div>
                  <input type="file" />
                </div>
              </Col>
              <Col md={6}>
                <div className="privacy">
                  <p>
                    We will process your personal information in accordance with
                    our <a>Privacy Policy.</a>
                  </p>
                  <label class="checkbox">
                    <div>
                      <input type="checkbox" class="checkbox__input" />
                      <span class="checkbox__inner"></span>
                    </div>
                    <p>
                      I would like to be contacted with news and updates about
                      your events and services
                    </p>
                  </label>
                </div>
              </Col>
            </Row>

            <div className="btn-div">
              <button>Send message</button>
            </div>
          </form>
        </div>
      </section> */}

      {/* <footer>
        <div className="container">
          <Row>
            <Col md={3}>
              <div className="logoDiv">
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/footer_logo.svg" />
              </div>
            </Col>
            <Col md={9}>
              <div className="heading">
                <h2>
                  We are an enterprise software development company that is
                  inspired by the future
                </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="heading">
                <h5>Offices:</h5>
                <ul>
                  <li>Aliso Viejo, CA</li>
                  <li>Warsaw, PL</li>
                  <li>Kyiv, UA</li>
                </ul>
              </div>
            </Col>
            <Col md={9}>
              <div className="heading">
                <h5>
                  <a href="mailto:hello@itrexgroup.com">hello@itrexgroup.com</a>
                </h5>
                <h5>
                  <a href="tel:+12134367785">+1 213 436 7785</a>
                </h5>
              </div>
              <div className="socioIcons">
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_facebook.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_instagram.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_youtube.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_in.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_twitter.svg" />
              </div>
            </Col>
          </Row>

          <Row className="footerLast">
            <Col md={3}>
              <div className="text-div">
                <span>Privacy Policy</span>
                <span>©2023 Itrex Group</span>
              </div>
            </Col>
            <Col md={9}>
              <div className="certIcons">
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert1.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert2.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert3.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert4.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert5.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert6.svg" />
                <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert7.svg" />
              </div>
            </Col>
          </Row>
        </div>
      </footer> */}
    </div>
  );
}

export default Home;
