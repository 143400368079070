import React from "react";
import styles from "./Button.module.css";

const Button = ({ btnText }) => {
  return (
    <div class={styles.button}>
      <div class={styles.text}>{btnText}</div>
    </div>
  );
};

export default Button;
