import React from "react";
import "./Footer.scss";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <Row>
          <Col md={3}>
            <div className="logoDiv">
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/footer_logo.svg" />
            </div>
          </Col>
          <Col md={9}>
            <div className="heading">
              <h2>
                We are an enterprise software development company that is
                inspired by the future
              </h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <div className="heading">
              <h5>Offices:</h5>
              <ul>
                <li>Aliso Viejo, CA</li>
                <li>Warsaw, PL</li>
                <li>Kyiv, UA</li>
              </ul>
            </div>
          </Col>
          <Col md={9}>
            <div className="heading">
              <h5>
                <a href="mailto:hello@itrexgroup.com">hello@itrexgroup.com</a>
              </h5>
              <h5>
                <a href="tel:+12134367785">+1 213 436 7785</a>
              </h5>
            </div>
            <div className="socioIcons">
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_facebook.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_instagram.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_youtube.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_in.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/icon_twitter.svg" />
            </div>
          </Col>
        </Row>

        <Row className="footerLast">
          <Col md={3}>
            <div className="text-div">
              <span>Privacy Policy</span>
              <span>©2023 Itrex Group</span>
            </div>
          </Col>
          <Col md={9}>
            <div className="certIcons">
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert1.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert2.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert3.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert4.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert5.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert6.svg" />
              <img src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert7.svg" />
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
