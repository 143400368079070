import React from "react";
import Header from "../Common/Header/Header";
import ContactForm from "../Common/ContactForm/ContactForm";
import Footer from "../Common/Footer/Footer";

function Layout(props) {
  return (
    <div className="layoutMain">
      <Header />
      <div className="screens">{props.children}</div>
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Layout;
