import React from "react";
import Hero from "../../../Common/Hero/Hero";

const Data = () => {
  return (
    <div>
      <Hero
        imageLink={
          "https://itrexgroup.com/wp-content/uploads/2021/03/data-services-and-solutions-development.png"
        }
        heading={"Big data consulting services and solutions"}
        text={
          "Unearth invaluable insights hidden within your business challenges. Our big data consulting services combine innovation, strategy, and engineering to reshape your decision-making."
        }
        btnText={"Talk to Our Big Data Consultants "}
        bgColor={"#0A0409"}
      />
    </div>
  );
};

export default Data;
