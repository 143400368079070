import React from "react";
import Hero from "../../../Common/Hero/Hero";
import CustomButton from "../../../Common/Button/Button";

const techStack = [
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/Spark-wide.svg",
  },
  {
    image:
      "https://itrexgroup.com/wp-content/uploads/2020/12/tensorflow-bw.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/theano.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/caffe.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/keras-bw.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/GENSIM.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/Scikit-w.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/Spark-wide.svg",
  },
  {
    image:
      "https://itrexgroup.com/wp-content/uploads/2020/12/tensorflow-bw.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/theano.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/caffe.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/keras-bw.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/GENSIM.svg",
  },
  {
    image: "https://itrexgroup.com/wp-content/uploads/2020/12/Scikit-w.svg",
  },
];

const ArtificialIntelligence = () => {
  return (
    <div className=" mx-auto my-0">
      <Hero
        imageLink={
          "https://itrexgroup.com/wp-content/uploads/2021/01/AI-preview-1.jpg"
        }
        heading={"AI consulting & development services"}
        text={
          " From image analysis and human activity recognition to semantic search and manual task automation, we provide AI consulting services and develop custom artificial intelligence solutions that give you a competitive edge"
        }
        btnText={"Talk to Our AI Developers"}
        bgColor={"#030617"}
      />
      {/* section 2 */}
      <div className="p-20 ">
        <h2 className="font-semibold">
          AI consulting and development company with extensive know-how
        </h2>
        <div className="flex">
          <div className="flex-[0.6] my-4">
            <p className="font-medium text-lg">
              An artificial intelligence consulting and development company with
              over a decade of experience, we will help you harness machine
              learning algorithms and the latest artificial intelligence tools
              and technologies for exponential growth.
            </p>
          </div>
        </div>
        {/* 6 cards section */}
        <div>
          <div className="flex flex-wrap justify-between items-center">
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2021/01/ML.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Machine Learning
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2020/12/business-drivers.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Deep Learning
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2021/01/NLP.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Natural Language processing
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
          </div>
          <div className="mt-2 flex justify-between items-center flex-wrap">
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2021/01/CV.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Computer Vision
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2020/12/get-started.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Generative AI
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
            {/* each card */}
            <div className="w-[350px] h-[300px] ">
              <img
                className="w-12"
                src="https://itrexgroup.com/wp-content/uploads/2020/12/partner.svg"
                loading="lazy"
                alt=""
              />
              <h3 className="text-[#4861FC] mt-3 mb-2 cursor-pointer hover:opacity-75">
                Robotics
              </h3>
              <p className="text-gray-700 text-lg font-medium ">
                Applying our expertise in supervised, unsupervised, and
                reinforcement machine learning, we build smart systems that make
                best decisions with little to no human help
              </p>
            </div>
          </div>
          <CustomButton btnText={"Discuss Your AI Concept"} />
        </div>
      </div>
      {/* Section 3 Repeated Image with 2 headings */}
      <div className="relative h-[475px]  flex items-center justify-start ">
        <div className=" !z-10  px-20 ">
          <h1 className="text-white text-[55px] font-semibold">
            Our AI solutions
          </h1>
          <h1 className="text-[#FFDB3B] text-[55px] font-semibold">in focus</h1>
        </div>
        <div className="-z-10 absolute  w-screen h-full ">
          <img
            className="object-cover bg-no-repeat w-screen h-[475px]  "
            src="https://itrexgroup.com/wp-content/uploads/2021/01/AI-solutions-1-e1664276277442.jpg"
            alt="..."
          />
        </div>
      </div>
      {/* yellow section 4 */}
      <div className=" bg-[#FFDB3B] flex">
        <div className=" max-w-[1080px] mx-auto my-16 flex">
          <span className="flex-[0.6] font-semibold text-lg ">
            As an artificial intelligence consulting company, we are confident
            that AI has limitless potential to transform your business. Explore
            a variety of AI use cases that highlight our capabilities and
            demonstrate the technology's potential. From improving operations to
            boosting your profits, our customized AI services guarantee real
            growth and unmatched success.
          </span>
        </div>
      </div>
      {/* 4 cards Section */}
      <div className="mx-auto max-w-[1080px] px-20  pt-20">
        <h2 className="my-4 font-bold">
          AI solutions to analyze and understand the physical world
        </h2>
        <div className="flex justify-between">
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Manual task automation</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Optical character recognition and data capturing solutions for
                extracting information from any documents to eliminate manual
                processes
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Facial recognition</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Person identification based on live captures and digital images
                for biometric authentication, access control, and other security
                purposes
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Image data labelling</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Deep learning models that automatically identify and tag people
                and objects in pictures and videos
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">
                  Human activity recognition
                </h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Intelligent models for human posture recognition used in sports
                training, surveillance, healthcare, and more
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* 4 cards Section */}
      <div className="mx-auto max-w-[1080px] px-20  pb-20">
        <h2 className="my-4 font-bold">
          AI solutions to grasp human language and emotions
        </h2>
        <div className="flex justify-between">
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Speech recognition</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Real-time speech processing and voice interfaces to automate
                routine tasks and take user experience to the next level
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Semantic search</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Advanced, NLP-powered algorithms to dig up search results that
                match a user’s intent best
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Text classification</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                NLP-powered categorization of emails, posts, and other textual
                information for sentiment analysis, topic labeling, and spam
                detection
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <div class="cursor-crosshair">
                <img
                  className="w-10"
                  src="https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Emotion recognition</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Analyzing images and videos to detect and recognize emotions and
                better understand your customers’ response to your products and
                services
              </p>
            </div>
          </div>
        </div>
        <div className="my-4">
          <CustomButton btnText={"Lets Build Your AI Solution"} />
        </div>
      </div>
      {/* Section 3 Repeated Image with 2 headings */}
      <div className="relative h-[475px]  flex items-center justify-start ">
        <div className=" !z-10  px-20 ">
          <h1 className="text-white text-[55px] font-bold">
            AI consulting and development services
          </h1>
          <h1 className="text-[#FFDB3B] text-[55px] font-bold">
            for your industry
          </h1>
        </div>
        <div className="-z-10 absolute  w-screen h-full ">
          <img
            className="object-cover bg-no-repeat w-screen h-[475px]  "
            src="https://itrexgroup.com/wp-content/uploads/2021/01/AI-industry-3.jpg"
            alt="..."
          />
        </div>
      </div>
      {/* yellow section 4 */}
      <div className=" bg-[#FFDB3B] flex">
        <div className=" max-w-[1080px] mx-auto my-16 flex">
          <span className="flex-[0.6] font-semibold text-lg ">
            Using our knowledge of industry-specific business processes and
            challenges, we tailor our artificial intelligence solutions to your
            particular needs. Whether you want to automate back-office
            operations, boost customer experience, improve security, or launch a
            truly innovative software product, our AI consultants and developers
            are up for the challenge.
          </span>
        </div>
      </div>
      {/* 3 cards Section */}
      <div className="mx-auto max-w-[1080px] px-20   pt-20 " id="ai-industries">
        <div className="flex justify-between">
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/08/health-book-fill-1.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Healthcare & Biotech</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Improve diagnostics and care delivery, enhance patient-doctor
                communication, and optimize hospital operations and population
                health management with AI-powered healthcare solutions
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/07/microscope-fill.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Life Sciences</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Facilitate the development of new drugs and drug repurposing,
                draw insights about drug candidates, targets, and biomarkers, as
                well as improve clinical trials with our powerful yet intuitive
                AI solutions
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/08/bank-fill.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Finance & Banking</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Automate banking and financial processes, reduce operational
                costs, enhance security, and enjoy other benefits of AI in
                banking and finance
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* 3 cards Section with button */}
      <div className="mx-auto max-w-[1080px] px-20   pb-20">
        <div className="flex justify-between">
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/08/health-book-fill-1.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Retail & E-commerce</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Make faster decisions, enjoy accurate demand forecasting,
                streamline inventory management, optimize the flow of goods, and
                better understand your customers with AI for retail
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/07/microscope-fill.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">
                  Supply Chain & Logistics
                </h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Gain total control over shipments, reduce paperwork, and
                simplify vendor communication, deliveries, and warehousing
                operations with AI-enhanced software
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-[30%] pr-2">
            <div class="bg-white py-6 ">
              <div>
                <img
                  className="w-14"
                  src="https://itrexgroup.com/wp-content/uploads/2022/08/bank-fill.png"
                  alt="..."
                />
                <h2 class=" font-bold text-2xl mt-4">Education & eLearning</h2>
              </div>
              <p class="text-gray-500 mt-2 font-medium ">
                Personalize learning programs, produce smart content, automate
                administrative tasks, and provide access to education for
                students with special needs with AI in education
              </p>
            </div>
          </div>
        </div>
        <div className="my-4">
          <CustomButton btnText={"Lets Solve your challenges with AI"} />
        </div>
      </div>
      {/* Section 3 Repeated Image with 2 headings */}
      <div className="relative h-[475px]  flex items-center justify-start ">
        <div className=" !z-10  px-20 ">
          <h1 className="text-white text-[55px] font-bold">
            Our approach to AI software
          </h1>
          <h1 className="text-[#FFDB3B] text-[55px] font-semibold">
            development and consulting
          </h1>
        </div>
        <div className="-z-10 absolute  w-screen h-full ">
          <img
            className="object-cover bg-no-repeat w-screen h-[475px]  "
            src="https://itrexgroup.com/wp-content/uploads/2021/01/AI-industry-3.jpg"
            alt="..."
          />
        </div>
      </div>
      {/* yellow section 4 */}
      <div className=" bg-[#FFDB3B] flex">
        <div className=" max-w-[1080px] mx-auto my-16 flex">
          <span className="flex-[0.6] font-semibold text-lg ">
            We provide end-to-end artificial intelligence consulting and AI app
            development services, from preparing data for AI algorithms to
            rolling out your system to thousands of users. We can also help you
            customize third-party AI as a Service solutions.
          </span>
        </div>
      </div>
      {/* Tab section */}
      {/* <div className="border max-w-[1080px] mx-auto my-10 py-4">
        <Tabs
          defaultActiveKey="Discovery"
          id="fill-tab-example"
          className="mb-3"
          fill
          style={tabStyles}
        >
          <Tab eventKey="Discovery" title="Discovery" style={tabTitleStyles}>
            <p>
              We take a deep dive into your data, challenges, and IT
              capabilities to spot new business opportunities, nail down use
              cases, and map the AI software development journey
            </p>
          </Tab>
          <Tab eventKey="Design" title="Design" style={tabTitleStyles}>
            Tab content for Design
          </Tab>
          <Tab eventKey="POC" title="POC" style={tabTitleStyles}>
            Tab content for POC
          </Tab>
          <Tab
            eventKey="Implementation"
            title="Implementation"
            style={tabTitleStyles}
          >
            Tab content for Loooonger Tab
          </Tab>
        </Tabs>
      </div> */}
      {/* Section 3 Repeated Image with 2 headings */}
      <div className="relative h-[475px]  flex items-center justify-start ">
        <div className=" !z-10  px-20 ">
          <h1 className="text-white text-[55px] font-bold">
            The key pillars of our AI consulting
          </h1>
          <h1 className="text-[#FFDB3B] text-[55px] font-semibold">
            and development services
          </h1>
        </div>
        <div className="-z-10 absolute  w-screen h-full ">
          <img
            className="object-cover bg-no-repeat w-screen h-[475px]  "
            src="https://itrexgroup.com/wp-content/uploads/2021/01/AI-industry-3.jpg"
            alt="..."
          />
        </div>
      </div>
      {/* 4 cards without Images */}
      <div className="mx-auto max-w-[1080px] px-20  pb-20">
        <div className="flex justify-between">
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <h3 class=" font-bold text-2xl mt-4">Data quality</h3>
              <p class="text-gray-400 mt-2 font-medium ">
                We carry out thorough data validation and cleansing to make sure
                insights are derived from high-quality data
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <h3 class=" font-bold text-2xl mt-4">Explainability</h3>
              <p class="text-gray-400 mt-2 font-medium ">
                We employ white-box AI models, so users understand the
                decision-making process behind AI algorithms
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <h3 class=" font-bold text-2xl mt-4">Predictable ROI</h3>
              <p class="text-gray-400 mt-2 font-medium ">
                We start our AI consulting and development projects with a proof
                of concept to let you tap into the value of AI without high
                upfront investments
              </p>
            </div>
          </div>
          {/* each Card */}
          <div class="w-full md:w-1/4 pr-2">
            <div class="bg-white py-6 ">
              <h3 class=" font-bold text-2xl mt-4">Security</h3>
              <p class="text-gray-400 mt-2 font-medium ">
                We put security procedures in place to guarantee your data and
                infrastructure won’t ever be compromised
              </p>
            </div>
          </div>
        </div>
        <div className="my-4">
          <CustomButton btnText={"Kick Off AI Consulting and Development"} />
        </div>
      </div>
      {/* Section 3 Repeated Image with 2 headings */}
      <div className="relative h-[475px]  flex items-center justify-start ">
        <div className=" !z-10  px-20 ">
          <h1 className="text-white text-[55px] font-bold">AI Development</h1>
          <h1 className="text-[#FFDB3B] text-[55px] font-semibold">
            Tech Stack
          </h1>
        </div>
        <div className="-z-10 absolute  w-screen h-full ">
          <img
            className="object-cover bg-no-repeat w-screen h-[475px]  "
            src="https://itrexgroup.com/wp-content/uploads/2022/08/artificial-intelligence-consulting.jpeg"
            alt="..."
          />
        </div>
      </div>
      {/* Tech Stack Table */}
      <div className="max-w-[1080px] mx-auto mt-20 px-20 ">
        <div className="flex flex-wrap  ">
          {techStack.map((e, i) => (
            <div
              key={i}
              className="w-1/5 h-40 border  flex justify-center items-center"
            >
              <img className="w-20" src={e.image} alt={i} />
            </div>
          ))}
        </div>
        <div className="my-4">
          <CustomButton btnText={"Talk to Our AI Experts"} />
        </div>
      </div>
    </div>
  );
};

export default ArtificialIntelligence;
