import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./Layout/Layout";
import Home from "./Screens/Home/Home";

import "./Common/common.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ArtificialIntelligence,
  Automation,
  BlockChain,
  Data,
  IOT,
} from "./Screens/Solutions";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/services/artificial-intelligence"
              element={<ArtificialIntelligence />}
            />
            <Route path="/services/big-data" element={<Data />} />
            <Route
              path="/services/intelligent-process-automation"
              element={<Automation />}
            />
            <Route
              path="/services/blockchain-development"
              element={<BlockChain />}
            />
            <Route
              path="/services/iot-development-consulting"
              element={<IOT />}
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
