import React from "react";
import "./Hero.scss";

const Hero = ({ imageLink, heading, text, btnText, bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }} class={`bgimage relative `}>
      <div className="absolute top-0 right-0 h-full w-[50%]">
        <img
          className="h-full w-full object-cover "
          alt="..."
          src={imageLink}
        />
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12  ">
            <h1 className="text-white font-black font text-[55px]  px-12 mt-20 mb-5  ">
              {heading}
            </h1>
            <p className="px-12 text-white text-lg">{text}</p>
            <p className="px-12 ">
              {/* <div className="btn-div">
                <button>{btnText}</button>
              </div> */}
              <div class="button btn-div">
                <div class="text">{btnText}</div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
